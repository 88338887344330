import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import Tablet from "../../../Assets/Images/tablets.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation, useParams } from "react-router-dom";
import Bottles from "../../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../../Assets/pharamacyImages/Satchels.png";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import Noteimage from "../../../Assets/Images/Icons/note.svg";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import ReliableImage from "../../../Assets/Images/Cart/reliable.svg";
import SecureImage from "../../../Assets/Images/Cart/safe-secure.svg";
import GenuineImage from "../../../Assets/Images/Cart/geniun-products.svg";
import http from "../../../Redux/Services/http-common";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import moment from "moment";
import { FileTextOutlined, LeftOutlined, ReadOutlined, RightOutlined } from "@ant-design/icons";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import Loader from "../../Shared/loader";
import { Helmet } from "react-helmet";
import { EnglishText } from "../../PageRouting/EnglishText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trackAddToCartEvent } from "../../utils/analytics/events";
import { Button, Skeleton } from "antd";
import CurebayButton from "../../Shared/Button/PrimaryButton";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getToken } from "../../../Redux/Action/userActions";
import Login from "../../Authentications/Login";
function ProductInfoPage(props) {
  const userData = useSelector((state) => state.authReducer.patientData);
  const [quantity, setQuantity] = useState(1);
  const { medicine: medicineseoId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [recentlyOrderMedicine, setRecentlyOrderMedicine] = useState([]);
  const [medicineDetail, setMedicineDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(-1);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [expectedDelivery, setExpectedDelivery] = useState();
  const [grandTotal, setGrandTotal] = useState();
  const [currIdx, setCurrIdx] = useState(0);
  const [alternateMedicine, setAlternativeMedicineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [medicineImages, setMedicineImages] = useState();
  const [selectedImage, setSelectedImage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [big, setBig] = useState(null);
  const [medicineProp, setMedicineProp] = useState(false);
  const [pendingAddToCart, setPendingAddToCart] = useState(null);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const handleScroll = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  console.log(medicineDetail, "MedsDetails");
  useEffect(() => {
    handleScroll();
    const medicineTotal =
      cartList?.patientMedicineOrder?.length > 0 &&
      cartList?.patientMedicineOrder[0]?.totalAmount;
    const labTotal =
      cartList?.patientLabTestsOrder?.length > 0 &&
      cartList.patientLabTestsOrder[0]?.totalAmount;
    const grandTotal = medicineTotal + labTotal;
    setGrandTotal(grandTotal);
  }, []);

  useEffect(() => {
    const hour = new Date().getHours();
    console.log(typeof hour);
    if (hour > 20 || hour < 8) {
      setExpectedDelivery("11 AM");
    } else {
      if (hour > 12) {
        const time = hour - 12;
        setExpectedDelivery(`${time + 3} PM`);
      } else {
        const time = hour + 3;
        if (time > 12) {
          setExpectedDelivery(`${time - 12} PM`);
        } else {
          setExpectedDelivery(`${time} AM`);
        }
      }
    }
  }, []);

  const cartCount = (list) => {
    let count = 0;
    list?.patientMedicineOrder?.forEach((item) => {
      count = count + item.patientMedicineOrderDetailsList.length;
    });

    list?.patientLabTestsOrder?.forEach((item) => {
      count = count + item.patientLabTestsOrderDetailsList.length;
    });
    //  list?.patientLabTestsOrder.forEach(item => {
    //   count
    //  });
    return count;
  };

  useEffect(() => {
    if (medicineseoId) {
      getMedicineDetail();
    }
  }, [medicineseoId]);

  useEffect(() => {
    dispatch(getToken());
    if (location?.state?.isLoginModalOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [location?.state?.isLoginModalOpen]);

  const getMedicineDetail = () => {
    http
      .get(`${process.env.REACT_APP_ELASTIC_BASEURL}medicine/${medicineseoId}`)
      .then((res) => {
        if (res.status == 200) {
          setMedicineDetail(res.data);
          getAlternativeMedicine(res.data);
        }
        console.log("MedicineDetail: ", res);
      });
  };

  const getAlternativeMedicine = async ({
    id,
    composition,
    drugForm,
    unitPrice,
  }) => {
    const encodedDrugForm = encodeURIComponent(drugForm || "");
    const loc = patientinfoData?.state ? patientinfoData.state : 'od';

    const url = `${process.env.REACT_APP_NEW_ELASTIC}medicine/${id}?loc=${loc}`


    try {
      const res = await http.get(url);
      if (res.data) {
        const sortedAlternates = res.data.sort((a, b) => a.order - b.order);
        const filteredData = res.data?.filter(
          (item) => item?.isInStock && item?.isSubstitute
        );
        setAlternativeMedicineData(filteredData[0]);
      } else {
        setAlternativeMedicineData([]);
      }
    } catch (error) {
      console.error("Error fetching alternative medicines:", error);
      setAlternativeMedicineData([]);
    }
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const performAddToCart = (data, quantity) => {
    const finalQuantity = quantity < 1 ? 1 : quantity;
    const payload = {
      patientId: userData.code,
      drugCode: data?.id,
      drugName: data?.medicineName,
      drugCategory: data?.drugCategory,
      medicineTypeOfSell: data?.medicineTypeOfSell,
      medicineType: "",
      unitPrice: Number(data?.medicineRate),
      quantity: finalQuantity,
      prescriptionRequired: data?.prescriptionRequired,
      vendorId: data?.vendorId,
    };

    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        trackAddToCartEvent(data?.medicineRate, data?.medicineName);
        dispatch(getCartDetails(userData.code));
        toast.success("Item added to cart successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
        toast.error("Something went wrong. Couldn't add to cart.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  const addTocart = (e, data, index = 0, quantity = 1) => {
    if (!userData?.code) {
      e.preventDefault();
      setMedicineProp(true);
      setIsModalOpen(true);
      return;
    }

    setIsLoading(true);

    const finalQuantity = quantity < 1 ? 1 : quantity;
    const payload = {
      patientId: userData.code,
      drugCode: data?.id,
      drugName: data?.medicineName,
      drugCategory: data?.drugCategory,
      medicineTypeOfSell: data?.medicineTypeOfSell,
      medicineType: "",
      unitPrice: Number(data?.medicineRate),
      quantity: finalQuantity,
      prescriptionRequired: data?.prescriptionRequired,
      vendorId: data?.vendorId,
    };

    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        trackAddToCartEvent(data?.medicineRate, data?.medicineName);

        return dispatch(getCartDetails(userData.code));
      })
      .then(() => {
        toast.success("Item added to cart successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
        toast.error("Something went wrong. Couldn't add to cart.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  console.log(medicineseoId, "Sdvdsihousdguvdsvds");

  useEffect(() => {
    if (userData?.code) {
      setIsLoading(true);
      dispatch(getCartDetails(userData.code))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [userData?.code, medicineDetail?.seoNameId]);

  const openModal = () => {
    setIsOpen(true);
  };

  const resolvePharamcyImageq = (Image) => {
    let PharmacyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharmacyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharmacyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharmacyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharmacyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharmacyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharmacyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharmacyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharmacyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharmacyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharmacyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharmacyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharmacyImage = Tablet;
    } else {
      PharmacyImage = defaultMed;
    }
    return PharmacyImage;
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder?.length) {
        cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            ids.push(res.drugCode);
          }
        );
      }
    }
    return ids;
  };

  const drugIDsInCart = getProductsIDs();

  useEffect(() => {
    if (!medicineDetail?.id) {
      return;
    }
    if (typeof medicineDetail?.medicineImages === "string") {
      let result = medicineDetail?.medicineImages.replace(/[[\]]/g, "");
      let medicineImage = result.split(",");
      setMedicineImages(medicineImage);
      console.log(medicineImage);
      if (medicineImage?.length !== 0) {
        if (medicineImage.length === 1 && medicineImage[0] === "") {
          const medicineImg = resolvePharamcyImageq(
            medicineDetail.medicineTypeOfSell
          );
          setBig(medicineImg);
          setMedicineImages("");
        } else {
          setCurrIdx(0);
          setBig(medicineImage[0]);
        }
      }
      // else{
      //   console.log(medicineDetail.medicineTypeOfSell)
      //   const medicineImg = resolvePharamcyImageq(medicineDetail.medicineTypeOfSell);
      //   console.log(medicineImg);
      //   setBig(medicineImg);
      // }
    } else if (Array.isArray(medicineDetail.medicineImages)) {
      const imageArr = medicineDetail?.medicineImages;
      if (imageArr?.length > 0) {
        setCurrIdx(0);
        setBig(imageArr[0]);
      } else {
        const medicineImg = resolvePharamcyImageq(
          medicineDetail.medicineTypeOfSell
        );
        setBig(medicineImg);
      }
    } else {
      const medicineImg = resolvePharamcyImageq(
        medicineDetail.medicineTypeOfSell
      );
      setBig(medicineImg);
    }
  }, [medicineDetail?.id]);

  const Silder = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handlePrevClick = () => {
  //   const idx = currIdx;
  //   if(currIdx !== 0){
  //     setCurrIdx( idx - 1);
  //   }
  //   const imageUrl = medicineImages.find((item,idx)=> idx === (idx !== 0? idx-1:idx));
  //   setBig(imageUrl);
  // };

  const handlePrevClick = () => {
    const newIdx = currIdx - 1;
    if (newIdx >= 0) {
      setCurrIdx(newIdx);
      setBig(medicineImages[newIdx]);
    }
  };

  const handleNextClick = () => {
    const newIdx = currIdx + 1;
    if (newIdx < medicineImages.length) {
      setCurrIdx(newIdx);
      setBig(medicineImages[newIdx]);
    }
  };

  // const handleNextClick = () => {
  //   if (startIndex + 3 < medicineImages?.length) {
  //     setCurrIdx(startIndex + 1);
  //   }
  // };

  const handleImageClick = (imageUrl) => {
    setBig(imageUrl);
    setSelectedImage(false);
  };

  const redirectToMedicinePage = (item) => {
    // history.push('/medicine');
    history.push(`/medicine/${item}`);
  };

  async function getMedicineData(medicineCode, type) {
    if (medicineCode?.length) {
      var raw = JSON.stringify(medicineCode);

      dispatch(getAllProductByIds(raw))
        .then((res) => {
          if (type == "orderMedicines") {
            setRecentlyOrderMedicine(res);
          }
        })
        .catch((err) => { });
    } else {
    }
  }

  console.log(alternateMedicine, alternateMedicine?.medicineName, "dsvsdiovdsgugsidygvsdvds");

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_BASEURL}PatientRecent/Search?patientId=${userData?.code}&category=1`
      )
      .then((res) => {
        let drugCode = res.data.data.map((item) => item.itemCode);
        getMedicineData(drugCode, "recentMedicine");
        // setPreviouslyBrowsedMedicine(res.data.data)
      });
  }, [userData]);

  useEffect(() => {
    http.get(`${process.env.REACT_APP_BASEURL}Drug/commonDrug`).then((res) => {
      getMedicineData(res.data.data, "orderMedicines");
    });
  }, [userData]);

  const handleQuantityChange = async (newQuantity) => {
    if (newQuantity < 1) return;
    setLoading(true);
    try {
      const orderDetail =
        cartList?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.find(
          (item) => item.drugCode === medicineDetail.id
        );

      if (!orderDetail) {
        console.error("Order detail not found for the current medicine.");
        setLoading(false);
        return;
      }

      await axios.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${newQuantity}&Id=${orderDetail.id}`
      );

      dispatch(getCartDetails(userData.code));

      setQuantity(newQuantity);
      toast.success("Item Quantity Changed Successfully!", {});
    } catch (error) {
      console.error(`Failed to update item ${medicineDetail.id}:`, error);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteItem = async () => {
    setIsLoading(true);
    try {
      const orderDetail =
        cartList?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.find(
          (item) => item.drugCode === medicineDetail.id
        );
      if (!orderDetail) {
        console.error("Order detail not found for deletion.");
        setIsLoading(false);
        return;
      }

      await axios.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?status=0&Id=${orderDetail.id}`
      );

      setQuantity(0);

      // Await the dispatch to ensure the cart is updated before turning off the loader.
      await dispatch(getCartDetails(userData.code));

      toast.success("Item removed from cart!");
    } catch (error) {
      console.error(`Failed to remove item ${medicineDetail.id}:`, error);
      toast.error("Something went wrong. Couldn't remove item from cart.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };


  useEffect(() => {
    if (medicineDetail && cartList && cartList.patientMedicineOrder?.length) {
      const orderDetail =
        cartList.patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.find(
          (item) => item.drugCode === medicineDetail.id
        );
      if (orderDetail) {
        setQuantity(orderDetail.quantity);
      }
    }
  }, [medicineDetail, cartList]);

  if (!medicineDetail?.id) {
    return (
      <Layouts>
        <section className="bg-lightGray py-8 ">
          <div className=" w-[90%] mx-auto">
            <div className="w-full rounded-md px-8 py-5">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
            <div className="w-full flex md:flex relative gap-4 mt-5">
              <div className="md:w-[70%]">
                <div className="bg-White w-full  rounded-lg md:flex p-7 gap-5">
                  <div className="md:w-[40%] border border-gray h-[250px]  rounded-lg md:h-[250px] md:p-7">
                    <div className="flex justify-center">
                      <Skeleton.Image active />
                    </div>
                  </div>
                  <div className="md:w-[70%]">
                    <Skeleton active />
                    <div className="md:w-[60%] flex justify-between my-5">
                      {/* <Skeleton active/> */}
                      <div className="flex items-center justify-center w-[150px] h-[40px] ">
                        {/* <Skeleton /> */}
                      </div>
                    </div>
                    <div className="flex gap-3 items-center mb-3">
                      <Skeleton active />
                    </div>
                  </div>
                </div>
                <div className="bg-White w-full my-3 rounded-lg">
                  <div className="p-7">
                    <Skeleton active />
                  </div>
                </div>
              </div>

              <div className="md:w-[30%] ">
                <div className="bg-[#e1edff] w-full rounded-lg p-5 sticky top-0 mb-3">
                  <div className="bg-White rounded-md p-5">
                    <Skeleton active />
                  </div>
                  <div className="flex justify-between gap-3 mt-8">
                    <Skeleton active />
                  </div>
                </div>
              </div>
            </div>
            <section className="py-[50px] md:py-[100px]">
              <Skeleton active />
            </section>
          </div>
        </section>
      </Layouts>
    );
  }
  const verticalSliderSettings = {
    vertical: true,
    verticalSwiping: true,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const getUnitPrice = (rate, typeOfSell, unitPack) => {
    const numRate = Number(rate);
    const pack = Number(unitPack) || 1;
    const lowerType = typeOfSell?.toLowerCase();

    if (lowerType?.includes("injection")) {
      return numRate;
    }

    return numRate / pack;
  };

  const mainUnitPrice = getUnitPrice(
    medicineDetail.medicineRate,
    medicineDetail.medicineTypeOfSell,
    medicineDetail.unitPack
  );
  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Layouts>
        <ToastContainer />
        <Helmet>
          <title>
            {medicineDetail?.medicineName.length > 30
              ? `${medicineDetail?.medicineName.substring(0, 43)}…`
              : medicineDetail?.medicineName}
          </title>
          <meta
            name="description"
            content={
              medicineDetail?.composition?.charAt(0)?.toUpperCase() +
              medicineDetail?.composition?.slice(1) +
              medicineDetail?.medicineName +
              " " +
              medicineDetail?.medicineType?.charAt(0)?.toUpperCase() +
              medicineDetail?.medicineType?.slice(1) +
              " " +
              medicineDetail?.drugForm?.charAt(0)?.toUpperCase() +
              medicineDetail?.drugForm?.slice(1)
            }
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <section className="bg-lightGray py-8 ">
          <div className=" w-[90%] mx-auto">
            <div className="w-full bg-primaryColor rounded-md px-8 py-5">
              <div className="font-medium md:text-2xl text-White">
                Medicine Details
              </div>
            </div>
            <div className="w-full md:flex relative gap-4 mt-5">
              <div className="md:w-[70%]">
                <div className="bg-White w-full  rounded-lg md:flex p-7 gap-5">
                  <div className="md:w-[40%] border border-gray h-[250px]  rounded-lg md:h-[250px] md:p-7 bg-lightBlue">
                    <div className="flex justify-center">
                      <img
                        src={big}
                        alt="Tablet"
                        className="w-[150px] h-[150px] rounded-lg object-fill bg-[transparent]"
                      />
                    </div>

                    {/* )} */}
                    <div className="flex items-center justify-center gap-2 mt-2 w-full overflow-x-auto scrollWidth">
                      {medicineImages?.length > 0 && (
                        <button
                          onClick={handlePrevClick}
                          className={`disabled:opacity-50 text-primaryColor font-bold bg-lightBlue w-[40px] h-[40px] rounded-full ${currIdx === 0 ? "text-gray-400" : ""
                            }`}
                          disabled={currIdx === 0}
                        >
                          <LeftOutlined />
                        </button>
                      )}
                      {medicineImages?.length > 0 &&
                        medicineImages
                          ?.slice(currIdx, currIdx + 3)
                          .map((imageUrl, index) => (
                            <img
                              key={index}
                              src={imageUrl}
                              alt={`img${index}`}
                              onClick={() => handleImageClick(imageUrl)}
                              className="w-14 h-14 border border-lightGray rounded-sm p-2 "
                            />
                          ))}
                      {medicineImages?.length > 0 && (
                        <button
                          onClick={handleNextClick}
                          className={` disabled:opacity-50 text-primaryColor font-bold bg-lightBlue w-[40px] h-[40px] rounded-full ${currIdx + 3 >= medicineImages?.length
                            ? "text-gray-400"
                            : ""
                            }`}
                          disabled={currIdx + 1 >= medicineImages?.length}
                        >
                          <RightOutlined />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="md:w-[70%]">
                    <p className="font-semibold text-xl md:text-2xl text-primaryColor mb-2">
                      {medicineDetail.medicineName}
                    </p>
                    <p className="text-textGray font-light text-sm md:text-base">
                      {medicineDetail.manufacturer}
                    </p>
                    <div className="md:w-[60%] flex justify-between my-5">
                      <div>
                        <p className="font-semibold text-secondaryColor text-2xl">
                          ₹ {medicineDetail.medicineRate}
                        </p>
                        <p className="text-textGray text-xs">
                          Inclusive of all taxes
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center mb-3">
                      <p className="text-textGray font-medium text-xs mb-0">
                        Select Pack Size :
                      </p>
                      <div className="flex items-center gap-3">
                        <button className="bg-lightBlue text-xs font-medium text-primaryColor border-none rounded-full px-3 py-1.5">
                          {medicineDetail.medicineTypeOfSell}
                        </button>
                      </div>
                    </div>
                    {/* <button
      // className={`${
      //   !drugIDsInCart.includes(medicineDetail.id)
      //     ? "bg-primaryColor text-White hover:bg-primaryColor"
      //     : "border border-secondaryColor bg-secondaryColor disabled:opacity-75 mt-3"
      // }  w-[30%] rounded-lg text-md`}
      className={`px-6 py-3 rounded-md font-semibold transition h-12 ${
        drugIDsInCart.includes(medicineDetail.drugCode)
          ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
          : "bg-primaryColor text-White hover:bg-secondaryColor"
      }`}
      onClick={(e) => addTocart(e, medicineDetail, 0, quantity)}
      loading={isAdding !== -1}
      disabled={drugIDsInCart.includes(medicineDetail.id)}
      size="large"
    >
      {drugIDsInCart.includes(medicineDetail.drugCode)
        ? "Added"
        : "Add to cart"}
    </button> */}
                    {drugIDsInCart.includes(medicineDetail.id) &&
                      quantity > 0 ? (
                      <div className="flex items-center justify-center w-[150px] h-[40px]">
                        {/* Minus Button */}
                        <button
                          className="w-[35%] h-full text-3xl font-light border border-textGray rounded-s-md flex items-center justify-center"
                          onClick={() => handleQuantityChange(quantity - 1)}
                          disabled={quantity <= 1 || loading}
                        >
                          {quantity <= 1
                            ? // When quantity is 1, show a blocked (ban) icon instead of the normal minus sign
                            "-"
                            : "–"}
                        </button>

                        {/* Quantity Display with Delete Icon */}
                        <div className="w-[50%] flex items-center justify-center h-full border-x border-y border-textGray text-xl text-primaryColor font-medium">
                          <span>{quantity}</span>
                          {/* Delete Icon (aligned next to quantity) */}
                        </div>

                        {/* Plus Button */}
                        <button
                          className="w-[35%] h-full text-3xl font-light border border-textGray rounded-e-md flex items-center justify-center"
                          onClick={() => handleQuantityChange(quantity + 1)}
                          disabled={loading}
                        >
                          +
                        </button>

                        <DeleteOutlined
                          onClick={handleDeleteItem}
                          style={{
                            marginLeft: "10px",
                            fontSize: "18px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          title="Remove item"
                        />
                      </div>
                    ) : (
                      <button
                        className="px-6 py-3 rounded-md font-semibold transition h-12 bg-primaryColor text-White hover:bg-secondaryColor"
                        onClick={(e) =>
                          addTocart(e, medicineDetail, 1, quantity)
                        }
                      >
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
                <div className="bg-White w-full my-3 rounded-lg">
                  <div className="p-7">
                    <p className="font-semibold text-base md:text-lg text-primaryColor">
                      Product Information
                    </p>
                    <div className="">
                      <div className="flex gap-4 my-3">
                        <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                          Contains <div>:</div>
                        </p>
                        <p className=" text-textGray text-sm w-[75%]">
                          {medicineDetail?.composition}
                        </p>
                      </div>
                      <div className="flex gap-4 my-3">
                        <p className="font-semibold text-textGray text-sm flex justify-between w-[25%]">
                          Medicine Type <div>:</div>
                        </p>
                        <p className="text-textGray text-sm  w-[75%]">
                          {medicineDetail?.medicineType}
                        </p>
                      </div>
                      {medicineDetail?.drugForm && (
                        <div className="flex gap-4 my-3">
                          <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                            Drug Form <div>:</div>
                          </p>
                          <p className="capitalize text-textGray text-sm w-[75%]">
                            {medicineDetail?.drugForm}
                          </p>
                        </div>
                      )}
                      <div className="flex gap-4 my-3">
                        <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                          Prescription<div>:</div>
                        </p>
                        <p className="capitalize text-textGray text-sm w-[75%]">
                          {medicineDetail?.prescriptionRequired}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start gap-3 mt-5">
                      <img src={Noteimage} alt="images" />
                      <p className="text-sm md:text-sm font-light text-textGray">
                        All the products are packed and stored safely and
                        delivery will be on time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {alternateMedicine && (
                <>
                  <div className=" rounded-xl overflow-hidden w-full max-w-2xl text-[15px]">
                    {/* Header */}
                    <div className="bg-[#075985] text-white text-[12px] sm:text-[18px] font-medium px-1 sm:px-5 py-3 flex items-center text-center justify-center gap-1 sm:gap-3 rounded-t-lg border-spark-glow">
                      <span className="text-[14px] sm:text-[20px]">🔥</span>
                      <span className="text-[12px] sm:text-[18px]">
                        Find CureBay Preferred Medicine&nbsp;
                        <span className="text-[#FF7700] font-extrabold text-[12px] sm:text-xl ml-0 sm:ml-1">
                          {((medicineDetail?.unitPrice - medicineDetail?.substituteUnitPrice) / medicineDetail?.unitPrice * 100).toFixed(2)}%
                        </span>{" "}
                        Cheaper
                      </span>
                    </div>


                    <div className="p-5 bg-White rounded-b-lg shadow-md h-[44%]">
                      <div className="flex flex-col sm:flex-row gap-3 sm:gap-5 items-start">
                        {/* Medicine Image */}
                        <img
                          src={
                            alternateMedicine.medicineImages?.length > 0
                              ? alternateMedicine.medicineImages[0]
                              : defaultMed
                          }
                          alt="Alternate Medicine"
                          className="w-28 h-28 sm:w-40 sm:h-40 object-contain rounded-md cursor-pointer"
                          onClick={() => redirectToMedicinePage(alternateMedicine?.seoNameId)}
                        />

                        {/* Medicine Info */}
                        <div className="flex-1 mt-3 sm:mt-6">
                          <h2 className="font-semibold text-[14px] sm:text-[20px] leading-snug">
                            {alternateMedicine?.medicineName}
                            <span className="text-secondaryColor ml-2 text-[14px] sm:text-[20px] font-bold">
                              ₹{alternateMedicine?.medicineRate}
                            </span>
                            <span className="text-textGray ml-2 text-sm sm:text-base">
                              ₹{alternateMedicine?.unitPrice}/{alternateMedicine?.drugForm}
                            </span>
                          </h2>

                          <p className="text-[14px] sm:text-[14px] text-gray-600 mt-2 sm:mt-3">
                            {alternateMedicine?.manufacturer}
                          </p>

                          <div className="flex items-center gap-2 sm:gap-3 mt-2 sm:mt-3">
                            <p className="text-[14px] sm:text-[14px] text-gray-700 font-medium">
                              {alternateMedicine?.composition}
                            </p>
                            <span className="bg-gray text-xs sm:text-sm px-2 sm:px-3 py-0.5 sm:py-1 rounded-xl">
                              {alternateMedicine?.medicineTypeOfSell}
                            </span>
                          </div>
                        </div>
                      </div>


                      {/* Add to Cart */}
                      <div className="mt-5 w-full">
                        <button
                          className={`w-full py-3 rounded-md text-[16px] font-semibold transition ${drugIDsInCart.includes(alternateMedicine.id)
                              ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                              : "bg-primaryColor text-White hover:bg-secondaryColor"
                            }`}
                          onClick={(e) =>
                            addTocart(e, alternateMedicine, alternateMedicine?.id, 1)
                          }
                          disabled={drugIDsInCart.includes(alternateMedicine.id)}
                        >
                          {drugIDsInCart.includes(alternateMedicine.id)
                            ? "Added"
                            : "Add to cart"}
                        </button>
                      </div>
                    </div>




                    {/* Terms & Conditions */}
                    {/* <div className="bg-[#ecfdf5] mt-4 p-5  rounded-b-lg shadow-md  px-5 py-4">
                      <div className="flex items-center justify-center gap-2 font-semibold text-[#075985] text-base mb-2">
                        <span>💬</span>
                        <span>Terms & Conditions</span>
                      </div>
                      <p className="text-sm text-gray-700 leading-relaxed">
                        We do not endorse or guarantee the effectiveness, safety, or legality of any medications listed on our application.
                        We are not responsible for verifying individual prescriptions to determine the suitability of any medication and
                        the purchase of medicines through this application is the sole responsibility of the buyer.
                        By using this application, you acknowledge that you are solely responsible for your own health decisions,
                        we disclaim any and all liabilities that may result from following any recommendations presented here.
                      </p>
                    </div> */}

                    <div
                      className="border  border-gray shadow-lg  rounded-lg bg-White  my-4 pb-4">
                      <div style={{ backgroundColor: "#e3ffef" }} className="flex justify-center items-center rounded-t-md">
                        <ReadOutlined style={{ fontSize: '20px', color: '#555' }} />
                        <p className="py-2 text-sm font-semibold text-center text-primaryColor ml-2">
                          Terms & Conditions
                        </p>
                      </div>
                      <div className="p-2">
                        <p className="text-textGray text-sm">
                          We do not endorse or guarantee the effectiveness, safety, or legality of any medications listed on our application. We are not responsible for verifying individual prescriptions to determine the suitability of any medication and the purchase of medicines through this application is the sole responsibility of the buyer. By using this application, you acknowledge that you are solely responsible for your own health decisions, we disclaim any and all liabilities that may result from following any recommendations presented here.
                        </p>
                      </div>
                    </div>
                  </div>

                </>
              )}
            </div>
          </div>
        </section>
        {isModalOpen && (
          <Login
            mode="Login"
            from={location?.state?.from}
            isModalOpen={isModalOpen}
            medsLogin={medicineProp}
          />
        )}
      </Layouts>
    </>
  );
}

export default ProductInfoPage;