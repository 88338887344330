import React, { useState } from 'react'
import HIW1 from "../../../Assets/Images/smartVitals/howitworks-1.svg";
import HIW2 from "../../../Assets/Images/smartVitals/howitworks-2.svg";
import HIW3 from "../../../Assets/Images/smartVitals/howitworks-3.svg";
// import HIW4 from "../../../Assets/Images/smartVitals/how-it-works-4.png";
import SubHeader from "../../Shared/SearchBar";
import Layouts from "../../Layouts/index";
import TrustIcon1 from "../../../Assets/Images/Icons/TrustIcon1.svg";
import Guid1 from "../../../Assets/Images/cameraPlacement.svg";
import Guid2 from "../../../Assets/Images/lightning.svg";
import Guid3 from "../../../Assets/Images/smartVitals/stay-still.svg";
import Guid4 from "../../../Assets/Images/smartVitals/steady_internet.svg";
import Guid5 from "../../../Assets/Images/smartVitals/neutral_expression.svg";
// import Guid3 from "../../../Assets/Images/minimalMovement.svg";
import { useApiData } from '../../../Context/ApidtaContext';

import { Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
// import Smartvitalsvid from "../../../Assets/videos/Smart vitals.mp4";
const SmartVitalsDetails = () => {
  const { caresathi } = useApiData();
  console.log(caresathi, "caresathicms")


  // const ContactlessMonitoring = caresathi?.attributes?.forthComp?.[1]?.image_url?.[0]?.image_url;
  const ContactlessMonitoring = caresathi?.attributes?.forthComp?.[1]?.image_url?.[5]?.image_url;

  const RealTimeAccuracy = caresathi?.attributes?.forthComp?.[1]?.image_url?.[4]?.image_url;
  ;

  const EmpatheticInsights = caresathi?.attributes?.forthComp?.[1]?.image_url?.[6]?.image_url;
  ;
  const PeaceOfMind = caresathi?.attributes?.forthComp?.[1]?.image_url?.[7]?.image_url;
  const AIDrivenAccuracy = caresathi?.attributes?.forthComp?.[1]?.image_url?.[8]?.image_url;

  const aitool = caresathi?.attributes?.thirdComp?.[0]?.image_url;
  const labinsight = caresathi?.attributes?.thirdComp?.[2]?.image_url;
  const caresathim = caresathi?.attributes?.headerComp?.[0]?.image_url;
  const dentalcare = caresathi?.attributes?.thirdComp?.[3]?.image_url;


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  return (
    <Layouts style={{ backgroundColor: "#004171" }}>
      {/* Title Content */}
      {/* <SubHeader /> */}
      <section className="home-banner min-h-[70vh] my-8  ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[90%] h-[70vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center relative">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <span className="text-secondaryColor text-sm md:text-6xl font-medium  mb-5 md:mb-3">
                SmartVitals
              </span>
              <h1 className="text-primaryColor md:text-White text-2xl md:text-3xl font-bold sm:mt-8 mt-0 ">
                Look. Scan, Know
              </h1>
              <span className="text-secondaryColor text-sm md:text-3xl font-medium  mb-1 md:mb-3">
                Stay Healthy!
              </span>

              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2 mb-4">
                A revolutionary way to check your vitals and mental wellness in just 30 seconds—no wearables, no hassle, just you and your phone.
              </p>
              <a
                className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
                style={{ color: "#ffff" }}
                href="/smart-vital-instr"
              >
                Check My Vitals Now
              </a>
              {/* <button
                className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor cursor"
                style={{ color: "#ffff" }}
                // href="ask-care-sathi?lab_report_analyser=true&popup=true"
                onClick={() => showModal()}
              >
                Check My Vitals Now
              </button> */}
            </div>
            {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                              <div className="w-full md:w-[90%] h-[300px] md:h-[350px] rounded banner-bg"></div>
                          </div> */}
<div className="hidden sm:block z-10 absolute right-1 top-12 rounded-[10px] overflow-hidden w-[750px] h-[400px]">
  <div className="w-full h-full rounded-[10px] overflow-hidden">
    <video
      className="w-full h-full object-cover"
      autoPlay
      loop
      muted
      preload="auto"
      playsInline
    >
      <source
        src={`${process.env.REACT_APP_IMG_BASEURL}d2c-web/SmartVital.mp4`}
        type="video/mp4"
      />
    </video>
  </div>
</div>


            <div className="flex md:hidden justify-center items-center gap-3 mt-5">
              <img src={TrustIcon1} alt="images" width={25} />
              <p className="text-primaryColor font-extralight text-sm">
                Trusted by{" "}
                <span className="font-medium">Over 100,000 Patients</span>
              </p>
            </div>
          </div>
          {/* <div className="swipicon">
                          <img src={Swipicon} alt="images" />
                      </div> */}
        </div>
      </section>

      <section className="bg-[#f3f8ff]">
        <div className="container mx-auto py[50px] md:py-[90px] px-4">
          <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
            Why <span className="text-secondaryColor">SmartVitals?</span>
          </h2>
          <div className='px-4 sm:px-10 py-6 bg-White text-xl text-[#676C80] rounded-lg'>
            <div>Your health should be easy to track. SmartVitals by CureBay uses advanced AI to analyze your vitals through a simple 30-second video scan—giving you instant insights into your heart rate, blood pressure, oxygen levels, and even stress levels. No extra devices, no complicated tests—just science-backed health monitoring at your fingertips.</div>
          </div>

        </div>
      </section>

      <section className="">
        <div className="container mx-auto py[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
            How it <span className="text-secondaryColor">Works</span>?
          </h2>
          <div className="px-[14px] pb-6 sm:pb-auto sm:px-auto md:w-[80%] mx-auto flex sm:flex-row flex-col justify-between items-start gap-10">
            {/* Step 1 */}
            <div className="gap-3 sm:gap-auto flex sm:flex-col flex-row text-left">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW1} alt="Step 1" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold text-left"
                  style={{ color: "#42BA85" }}
                >
                  STEP 1
                </div>
                <div
                  className="text-base font-semibold text-left"
                  style={{ color: "#004171" }}
                >
                  Record Video
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Record a 30-second video of your face in a well-lit space

                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full sm:w-20 w-24 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW2} alt="Step 2" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 2
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Scan Face
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  AI scans your facial blood flow to assess key health indicators
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW3} alt="Step 3" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 3
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Get Instant Results
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Get instant results on your physical and mental wellness
                </p>
              </div>
            </div>

            {/* Step 4 */}
            {/* <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row ">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW4} alt="Step 4" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 4
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Continuous Care
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Access your health data anytime, ensuring peace of mind wherever you are.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="bg-[#f3f8ff]">
        <div className="container mx-auto py[50px] md:py-[90px] px-4">
          <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
            What You Can <span className="text-secondaryColor">Expect</span>
          </h2>
          <div className="flex justify-center items-center min-h-screen">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-0">
              <div className="flex flex-col items-center">
                <img src={ContactlessMonitoring} alt="Touch-Free Monitoring" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Contact Health Check
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Hassle-free monitoring with zero physical contact
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={RealTimeAccuracy} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Comprehensive Vitals
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Track 12+ key metrics including BP, HR, and SpO₂.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={PeaceOfMind} alt="Convenient Setup" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Real-Time Wellness
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Immediate stress and mental health insights at your fingertips.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={EmpatheticInsights} alt="Peace Of Mind" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Smartphone Simplicity
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    No wearables needed—just your smartphone for all measurements.
                  </p>
                </div>
              </div>


              <div className="flex flex-col items-center sm:col-span-2 justify-self-center text-center ">
                <img src={AIDrivenAccuracy} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    AI-Driven Accuracy
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Fast, precise results powered by advanced AI.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className=" py-10 px-4">


        <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
          Set Up Guidelines for <span className="text-secondaryColor">Best Results</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 max-w-3xl mx-auto">
          {/* Good Lighting */}
          <div className="flex items-center space-x-4">
            <img
              src={Guid2}
              alt="Camera Placement"
              className=""
            />
            <div>
              <h3 className="text-xl font-semibold">Good lighting</h3>
              <p className="font-normal text-base">Ensure your face is well lit</p>
            </div>
          </div>

          {/* Stay Still */}
          <div className="flex items-center space-x-4">
            <img
              src={Guid3}
              alt="Camera Placement"
              className=""
            />
            <div>
              <h3 className="text-xl font-semibold ">Stay still</h3>
              <p className="font-normal text-base">Hold your phone steady and maintain eye contact</p>
            </div>
          </div>

          {/* Neutral Expression */}
          <div className="flex items-center space-x-4">
            <img
              src={Guid4}
              alt="Camera Placement"
              className=""
            />
            <div>
              <h3 className="text-xl font-semibold">Neutral expression</h3>
              <p className="font-normal text-base">Relax your face for an accurate scan</p>
            </div>
          </div>

          {/* Steady Internet */}
          <div className="flex items-center space-x-4  p-4 rounded-lg">
            <img
              src={Guid5}
              alt="Camera Placement"
              className=""
            />
            <div>
              <h3 className="text-xl font-semibold">Steady internet</h3>
              <p className="font-normal text-base">A smooth connection ensures quick analysis</p>
            </div>
          </div>
        </div>
      </section>



      <section className="bg-[#f3f8ff] py-10 px-4">
        <h2 className="text-center text-2xl md:text-4xl font-semibold text-gray-900 mb-8">
          What <span className="text-secondaryColor">SmartVitals</span> <span className="text-green-600">Measures</span> ?
        </h2>

        <div className="bg-white max-w-3xl mx-auto p-6  grid grid-cols-1 md:grid-cols-3 gap-4 bg-White rounded-lg">
          <div className="flex items-center space-x-3">
            <CheckOutlined className="text-secondaryColor" />
            <p className="text-gray-800">Heart Rate (BPM)</p>
          </div>
          <div className="flex items-center space-x-3">
            <CheckOutlined className="text-secondaryColor" />
            <p className="text-gray-800">Blood Pressure</p>
          </div>
          <div className="flex items-center space-x-3">
            <CheckOutlined className="text-secondaryColor" />
            <p className="text-gray-800">Oxygen Saturation (SpO₂)</p>
          </div>
          <div className="flex items-center space-x-3">
            <CheckOutlined className="text-secondaryColor" />
            <p className="text-gray-800">Respiratory Rate</p>
          </div>
          <div className="flex items-center space-x-3">
            <CheckOutlined className="text-secondaryColor" />
            <p className="text-gray-800">Mental Wellness Score</p>
          </div>
          <div className="flex items-center space-x-3">
            <CheckOutlined className="text-secondaryColor" />
            <p className="text-gray-800">Stress Level</p>
          </div>
        </div>
      </section>

      <section className="bg-lightGray mb-4">
        <div className="bg-[#023b72] py-12 flex flex-col justify-center items-center min-h-screen">
          <div className=" block sm:hidden text-center  z-10">
            <h2 className="text-3xl font-bold mb-2 text-White">
              Check Our Other
            </h2>
            <h3 className="text-3xl font-bold text-[#42BA85] mb-6">AI Tools</h3>
          </div>
          {/* Main Container */}

          <div className=" w-full max-w-5xl rounded-2xl flex flex-col  items-start justify-between relative overflow-hidden">
            {/* Left Section with Heading */}
            <div className="hidden sm:flex w-full  max-w-5xl bg-[#0a5a9c]  rounded-t-2xl  items-start justify-between relative overflow-hidden">
              <div className="text-white z-10 md:w-1/2 sm:pl-[50px] sm:pt-[100px]">
                <h2 className="text-3xl font-bold mb-2 text-White">
                  Check Our Other
                </h2>
                <h3 className="text-3xl font-bold text-[#42BA85] mb-6">
                  AI Tools
                </h3>
              </div>

              {/* Background Image */}
              <img
                src={aitool}
                alt="Doctor"
                className="  md:w-[66%] w-full object-cover rounded-t-2xl"
                style={{ zIndex: 1, maxHeight: "100%" }}
              />
            </div>

            {/* AI Tools Cards Section */}
            <div className=" rounded-b-xl bg-[#0a5a9c] grid grid-cols-1 md:grid-cols-3 gap-6 z-10 mt-8 md:mt-0 px-[50px] py-[45px]">
              {/* Card 1 */}
              <div className="bg-white rounded-xl overflow-hidden ">
                <img
                  src={labinsight}
                  alt="Labinsight"
                  className="w-full object-cover"
                />
                <div
                  className="p-4 bg-White rounded-b-xl "
                  style={{ height: "132px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    LabInsights
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    From uncertainty to clarity, we are with you
                  </p>
                  <a
                    href="/lab-insight"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={caresathim}
                  alt="Smart Vitals"
                  className="w-full  object-cover"
                />
                <div className="p-4  bg-White rounded-b-xl ">
                  <h4 className="text-lg font-semibold text-[#004171]">
                    CareSathi
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Get insights from your lab reports with AI precision
                  </p>
                  <a
                    href="/care-sathi"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 3 */}

              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={dentalcare}
                  alt="AI Powered Dentistry"
                  className="w-full  object-cover"
                />
                <div
                  className="p-4  bg-White rounded-b-xl"
                  style={{ height: "128px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    Oral Care
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Transform your smile with technology
                  </p>
                  <a
                    href="/services/oralcare"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={<h2 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>Coming Soon</h2>}
        open={isModalVisible}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleOk}
        centered
      >
        <p className='mt-4 text-md' >Thank you for being interested in what we do!</p>
      </Modal>
    </Layouts>
  );
};

export default SmartVitalsDetails;