import { useDispatch, useSelector } from "react-redux";
import CurebayButton from "./Button/PrimaryButton";
import Bottles from "../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../Assets/pharamacyImages/Medicines.jpg";
import { useHistory } from "react-router-dom";
import { addMedicineToCart } from "../utils/cart-utils";
import { useEffect, useState } from "react";
import {
  AddtoCart,
  getCartDetails,
} from "../../Redux/Action/cartPlaceOrderAction";
import { trackAddToCartEvent } from "../utils/analytics/events";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import http from "../../Redux/Services/http-common";
import axios from "axios";
import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import SubstituteCard from "./SubstituteCard";

const MedicineCard = ({ index = -1, cardItem, quantity: propQuantity = 1 }) => {

  console.log(cardItem,"derftgyh")
  const { cartList } = useSelector((state) => state.cartReducer);
  const userData = useSelector((state) => state.authReducer.patientData);
  const history = useHistory();
  const dispatch = useDispatch();

  // Local states for quantity and loading
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [visible,setVisible] =useState(false);

  // Get list of drug IDs in cart (using drugCode from order details)
  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder?.length) {
        cartList.patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.forEach(
          (res) => {
            ids.push(res.drugCode);
          }
        );
      }
    }
    return ids;
  };
  const drugIDsInCart = getProductsIDs();

  // Update local quantity from the Redux cart if this item is already in the cart
  useEffect(() => {
    if (cardItem && cartList && cartList.patientMedicineOrder?.length) {
      const orderDetail =
        cartList.patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.find(
          (item) => item.drugCode === cardItem.id
        );
      if (orderDetail) {
        setQuantity(orderDetail.quantity);
      }
    }
  }, [cardItem, cartList]);

  const redirectToMedicinePage = (e, item) => {
    history.push(`/medicine/${item.seoNameId}`);
  };

  const resolvePharamcyImageq = (Image) => {
    let PharamncyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharamncyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharamncyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharamncyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharamncyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharamncyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharamncyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharamncyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharamncyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharamncyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharamncyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharamncyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharamncyImage = Tablet;
    } else {
      PharamncyImage = defaultMed;
    }
    return PharamncyImage;
  };

  const handleImage = (images, type) => {
    if (typeof images === "string") {
      let result = images.replace(/[[\]]/g, "");
      let medicineImage = result.split(",");
      if (medicineImage.length !== 0) {
        if (medicineImage.length === 1 && medicineImage[0] === "") {
          return resolvePharamcyImageq(type);
        } else {
          return medicineImage[0];
        }
      } else {
        return resolvePharamcyImageq(type);
      }
    } else {
      return resolvePharamcyImageq(type);
    }
  };

  // Function to add the medicine to the cart
  const handleClick = (e, medicineItem) => {
    e.stopPropagation();
    if (Object.keys(userData).length === 0) {
      history.push(APP_ROUTES.HOME, { isLoginModalOpen: true });
      return;
    }
    setIsLoading(true);
    const payload = {
      patientId: userData?.code,
      drugCode: medicineItem?.id,
      drugName: medicineItem?.medicineName,
      drugCategory: medicineItem?.drugCategory,
      medicineTypeOfSell: medicineItem?.medicineTypeOfSell,
      medicineType: "",
      unitPrice: Number(medicineItem?.medicineRate),
      quantity: 1, // Always add 1 initially
      prescriptionRequired: medicineItem?.prescriptionRequired,
      vendorId: medicineItem?.vendorId,
    };
    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        trackAddToCartEvent(
          medicineItem?.medicineRate,
          medicineItem?.medicineName
        );
        dispatch(getCartDetails(userData.code));
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Function to update quantity using the same API call as in the product details page
  const handleQuantityChange = async (newQuantity) => {
    if (newQuantity < 1) return;
    setIsLoading(true);
    try {
      // Find the corresponding order detail for this medicine from the cart
      const orderDetail =
        cartList?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.find(
          (item) => item.drugCode === cardItem.id
        );
      if (!orderDetail) {
        console.error("Order detail not found for the current medicine.");
        setIsLoading(false);
        return;
      }
      await axios.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${newQuantity}&Id=${orderDetail.id}`
      );
      dispatch(getCartDetails(userData.code));
      setQuantity(newQuantity);
      toast.success("Quantity changed successfully!");
    } catch (error) {
      console.error(`Failed to update item ${cardItem.id}:`, error);
      toast.error("Something Went Wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to delete the item from cart immediately (sets quantity to 0)
  const handleDeleteItem = async () => {
    // Immediately update UI by setting local quantity to 0
    setQuantity(0);
    setIsLoading(true);
    try {
      const orderDetail =
        cartList?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.find(
          (item) => item.drugCode === cardItem.id
        );
      if (!orderDetail) {
        console.error("Order detail not found for deletion.");
        setIsLoading(false);
        return;
      }
      await axios.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?status=0&Id=${orderDetail.id}`
      );
      dispatch(getCartDetails(userData.code));
      toast.success("Item Deleted successfully!");
    } catch (error) {
      console.error(`Failed to remove item ${cardItem.id}:`, error);
      toast.error("Something Went Wrong!");
    } finally {
      setIsLoading(false);
    }
  };


  const percentagesubstitute = ((cardItem?.unitPrice - cardItem?.substituteUnitPrice)/ cardItem?.unitPrice) *100;



  return (
    
    <>
      <div key={index} className="bg-White rounded-lg  pointer">
        <div className="p-5">
        <div className="bg-lightBlue h-[150px]  flex justify-center items-center rounded-lg cursor-pointer">
          <img
            src={handleImage(
              cardItem.medicineImages,
              cardItem.medicineTypeOfSell
            )}
            alt="images"
            className="w-[90px] h-[90px] rounded-lg object-fill"
            onClick={(e) => redirectToMedicinePage(e, cardItem)}
          />
        </div>
        <div className="pt-3">
          <div className="cursor-pointer">
            <h5
              className="text-primaryColor text-lg mb-2 font-medium text-ellipsis overflow-hidden text-nowrap"
              onClick={(e) => redirectToMedicinePage(e, cardItem)}
            >
              {cardItem.medicineName}
            </h5>
            <div className="text-secondaryColor text-lg font-semibold flex items-baseline">
              <div className="flex w-fit">₹{cardItem.medicineRate}</div>
              <div className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                {cardItem.medicineTypeOfSell}
              </div>
            </div>
          </div>
          {/* Render quantity adjuster if item is in cart and local quantity is greater than 0 */}
          {drugIDsInCart.includes(cardItem.id) && quantity > 0 ? (
            <div className="flex items-center justify-center w-full mt-2">
              {/* Minus Button */}
              <button
                className="w-[30%] h-10 flex items-center justify-center border border-textGray rounded-l-md"
                onClick={() => handleQuantityChange(quantity - 1)}
                disabled={quantity <= 1 || isLoading}
              >
                {quantity <= 1 ? (
                  <MinusOutlined style={{ color: "#ccc" }} />
                ) : (
                  <MinusOutlined />
                )}
              </button>
              {/* Quantity Display */}
              <div className="w-[40%] h-10 flex items-center justify-center border-t border-b border-textGray text-primaryColor font-medium">
                {quantity}
              </div>
              {/* Plus Button */}
              <button
                className="w-[30%] h-10 flex items-center justify-center border border-textGray rounded-r-md"
                onClick={() => handleQuantityChange(quantity + 1)}
                disabled={isLoading}
              >
                <PlusOutlined />
              </button>
              {/* Delete Icon */}
              <DeleteOutlined
                onClick={handleDeleteItem}
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  color: "red",
                  cursor: "pointer",
                }}
                title="Remove item"
              />
            </div>
          ) : (
            <div className=" flex justify-center">
           
            <button
              className=" text-primaryColor   font-bold border border-primaryColor pl-10 pr-10 pt-2 pb-2  hover:text-secondaryColor rounded-lg text-lg mt-2 w-full"
              onClick={(e) => handleClick(e, cardItem)}
              loading={isLoading}
              size="large"
              type="primary"
            >
              ADD
            </button>
            </div>
          )}


        </div>
        </div>
        {cardItem.substituteUnitPrice && (
        

        <div
        className="relative mt-2 pt-2 pb-2 flex justify-between items-center bg-primaryColor text-White font-medium border  rounded-b-xl shadow-lg px-4 py-2 cursor-pointer overflow-hidden"
        onClick={() => setVisible(true)}
      >
        <span className="flex items-center gap-2">
          Save
          <span className="text-[#ffcc33] font-extrabold text-lg">
            {(percentagesubstitute).toFixed(2)} %
          </span>
          on substitute
        </span>
      
        {/* Right-aligned arrow */}
        <span className="text-white text-lg">
          ➤
        </span>
      
        {/* Spark border overlay */}
        <div className="absolute inset-0 rounded-b-xl pointer-events-none z-0 border-spark-glow"></div>
      </div>
      
      
 

)}
      </div>

      <Dialog
  visible={visible}
  onHide={() => setVisible(false)}
  position="bottom"
  header="Compare and Choose"
  
  // breakpoints={{ '960px': '100vw' }}
  modal
  dismissableMask
  className="sm:w-[30%] w-full"
>
<SubstituteCard cardItem={cardItem} onClose={() => setVisible(false)} drugIDsInCart={drugIDsInCart} quantity={quantity} handleQuantityChange={handleQuantityChange} handleDeleteItem={handleDeleteItem} handleClick={handleClick}  />
</Dialog>

    </>
  );
};

export default MedicineCard;
