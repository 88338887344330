import React, { useEffect, useState } from 'react';
import Bottles from "../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../Assets/pharamacyImages/Medicines.jpg";
import axios from 'axios';
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import { trackAddToCartEvent } from "../utils/analytics/events";
import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  AddtoCart,
  getCartDetails,
} from "../../Redux/Action/cartPlaceOrderAction";
import { ToastContainer, toast } from "react-toastify";

const SubstituteCard = ({cardItem,onClose,}) => {

  const [medicineData, setMedicineData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [isLoading, setIsLoading] = useState(false);
   const [selectedQuantity, setSelectedQuantity] = useState(0);
   const [recommendedQuantity, setRecommendedQuantity] = useState(0);
   
     const patientinfo = useSelector((state) => state.patientinfo);
     const { patientinfoData } = patientinfo;
     const userData = useSelector((state) => state.authReducer.patientData);
     const history = useHistory();
       const dispatch = useDispatch();
         const { cartList } = useSelector((state) => state.cartReducer);
   console.log(cardItem,patientinfoData?.state,"drfgb")
 
   useEffect(() => {
    const fetchMedicineDetails = async () => {
      try {
        const loc = patientinfoData?.state ? patientinfoData.state : 'od';
        const response = await axios.get(
          `${process.env.REACT_APP_NEW_ELASTIC}medicine/${cardItem?.id}`,
          {
            params: { loc: loc },
          }
        );
        console.log(response.data, 'Medicine Data');
  
        // Check conditions before setting state
        const filteredData = response.data?.filter(
          (item) => item?.isInStock && item?.isSubstitute
        );
  
        setMedicineData(filteredData[0]);
  
      } catch (error) {
        console.error('Error fetching medicine data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (cardItem?.id) {
      fetchMedicineDetails();
    }
  }, [cardItem?.id, patientinfoData?.state]);

  console.log(medicineData?.medicineName,patientinfoData,"sefrtg")
  
  

    const handleImage = (images, type) => {
        if (typeof images === "string") {
          let result = images.replace(/[[\]]/g, "");
          let medicineImage = result.split(",");
          if (medicineImage.length !== 0) {
            if (medicineImage.length === 1 && medicineImage[0] === "") {
              return resolvePharamcyImageq(type);
            } else {
              return medicineImage[0];
            }
          } else {
            return resolvePharamcyImageq(type);
          }
        } else {
          return resolvePharamcyImageq(type);
        }
      };

        const resolvePharamcyImageq = (Image) => {
          let PharamncyImage;
          if (Image.toUpperCase().includes("BOTTLE")) {
            PharamncyImage = Bottles;
          } else if (
            Image.toUpperCase().includes("CAPSULE") ||
            Image.toUpperCase().includes("CAPSULES")
          ) {
            PharamncyImage = Capsules;
          } else if (
            Image.toUpperCase().includes("CREAM") ||
            Image.toUpperCase().includes("CREAMS")
          ) {
            PharamncyImage = Creams;
          } else if (Image.toUpperCase().includes("CUREBAY")) {
            PharamncyImage = Curebay;
          } else if (Image.toUpperCase().includes("DEVICES")) {
            PharamncyImage = Devices;
          } else if (Image.toUpperCase().includes("DISPENSERS")) {
            PharamncyImage = Dispensers;
          } else if (Image.toUpperCase().includes("DROPLETS")) {
            PharamncyImage = Droplets;
          } else if (Image.toUpperCase().includes("EMULSIONS")) {
            PharamncyImage = Emulsions;
          } else if (
            Image.toUpperCase().includes("INJECTION") ||
            Image.toUpperCase().includes("INJECTIONS")
          ) {
            PharamncyImage = Injections;
          } else if (Image.toUpperCase().includes("LOTIONS")) {
            PharamncyImage = Lotions;
          } else if (Image.toUpperCase().includes("SATCHELS")) {
            PharamncyImage = Satchels;
          } else if (
            Image.toUpperCase().includes("TABLET") ||
            Image.toUpperCase().includes("TABLETS")
          ) {
            PharamncyImage = Tablet;
          } else {
            PharamncyImage = defaultMed;
          }
          return PharamncyImage;
        };



        const handleAddtocart = (e,medicineItem) => {
          e.stopPropagation();
          if (Object.keys(patientinfoData?.code).length === 0) {
            history.push(APP_ROUTES.HOME, { isLoginModalOpen: true });
            return;
          }
          setIsLoading(true);
          const payload = {
            patientId: patientinfoData?.code,
            drugCode: medicineItem?.id,
            drugName: medicineItem?.medicineName,
            drugCategory: medicineItem?.drugCategory,
            medicineTypeOfSell: medicineItem?.medicineTypeOfSell,
            medicineType: "",
            unitPrice: Number(medicineItem?.medicineRate),
            quantity: 1, // Always add 1 initially
            prescriptionRequired: medicineItem?.prescriptionRequired,
            vendorId: medicineItem?.vendorId,
          };
          fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((res) => {
              trackAddToCartEvent(
                medicineItem?.medicineRate,
                medicineItem?.medicineName
              );
              dispatch(getCartDetails(patientinfoData.code));
              //onClose();
            })
            .catch((err) => {
              console.error("Error adding to cart:", err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        };

        const percentagesubstitute = ((cardItem?.unitPrice - cardItem?.substituteUnitPrice)/ cardItem?.unitPrice) *100;

       
        const getProductsIDs = () => {
          let ids = [];
          if (cartList) {
            if (cartList?.patientMedicineOrder?.length) {
              cartList.patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.forEach(
                (res) => {
                  ids.push(res.drugCode);
                }
              );
            }
          }
          return ids;
        };
        const drugIDsInCart = getProductsIDs();
          console.log(drugIDsInCart,"fghj")
          const medicineId = medicineData?.id ? medicineData : cardItem;


        // useEffect(() => {
        
        //   if (medicineId  && cartList && cartList.patientMedicineOrder?.length) {
        //     const orderDetail =
        //       cartList.patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.find(
        //         (item) => item.drugCode === medicineId.id
        //       );
        //       console.log(orderDetail,"erftgyh")
        //     if (orderDetail) {
        //       setQuantity(orderDetail.quantity);
        //     }
        //   }
        // }, [medicineId, cartList]);

        useEffect(() => {
          if (cartList?.patientMedicineOrder?.length) {
            const list = cartList.patientMedicineOrder[0].patientMedicineOrderDetailsList;
        
            const selectedDetail = list.find(item => item.drugCode === cardItem?.id);
            if (selectedDetail) setSelectedQuantity(selectedDetail.quantity);
        
            if (medicineData?.id) {
              const recommendedDetail = list.find(item => item.drugCode === medicineData.id);
              if (recommendedDetail) setRecommendedQuantity(recommendedDetail.quantity);
            }
          }
        }, [cartList, cardItem?.id, medicineData?.id]);
        

        const handleQuantityChange = async (newQuantity, item) => {
          if (newQuantity < 1) return;
          setIsLoading(true);
          try {
            const orderDetail = cartList?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.find(
              (x) => x.drugCode === item.id
            );
            if (!orderDetail) {
              console.error("Order detail not found");
              setIsLoading(false);
              return;
            }
        
            await axios.get(`${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${newQuantity}&Id=${orderDetail.id}`);
            dispatch(getCartDetails(userData.code));
        
            if (item.id === cardItem.id) setSelectedQuantity(newQuantity);
            else setRecommendedQuantity(newQuantity);
        
            toast.success("Quantity changed successfully!");
          } catch (err) {
            toast.error("Something went wrong!");
          } finally {
            setIsLoading(false);
          }
        };
        
        const handleDeleteItem = async (item) => {
          setIsLoading(true);
          try {
            const orderDetail = cartList?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.find(
              (x) => x.drugCode === item.id
            );
            if (!orderDetail) {
              console.error("Order detail not found");
              setIsLoading(false);
              return;
            }
        
            await axios.get(`${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?status=0&Id=${orderDetail.id}`);
            dispatch(getCartDetails(userData.code));
        
            if (item.id === cardItem.id) setSelectedQuantity(0);
            else setRecommendedQuantity(0);
        
            toast.success("Item deleted successfully!");
          } catch (err) {
            toast.error("Something went wrong!");
          } finally {
            setIsLoading(false);
          }
        };

        const redirectToMedicinePage = (e, item) => {
          history.push(`/medicine/${item.seoNameId}`);
        };

   
        

  return (
<div className="w-full mx-auto">
  <div className="flex flex-col sm:flex-row justify-between items-stretch gap-4">
    
    {/* You Selected */}
    <div className="w-full sm:w-1/2  flex flex-col bg-White rounded-lg shadow-md hover:shadow-lg">
      <div className="text-center text-base font-medium text-gray-800 mb-2 px-2">
        You Selected
      </div>
      <hr className="border-t-[1.5px] border-[#bbf7d0] my-2" />

      <div className="p-2 transition-shadow duration-300 ease-in-out flex flex-col justify-between h-full">
        <div className='cursor-pointer' onClick={(e) => redirectToMedicinePage(e, cardItem)}>
          <div className="flex justify-center mb-4">
            <img
              src={handleImage(
                cardItem.medicineImages,
                cardItem.medicineTypeOfSell
              )}
              alt="medicine"
              className="w-[90px] h-[90px] rounded-lg object-cover"
             
            />
          </div>

           <div className="text-left ">
            <p className="text-primaryColor font-bold text-sm mb-[2px]">
              {cardItem?.medicineName}
            </p>

            <p className="text-sm text-[#1f2937] font-bold mb-[2px]">
              Rs. {cardItem?.medicineRate}
              <span className="text-[#6b7280] font-normal text-xs ml-2">
                Rs.{cardItem?.unitPrice}/{cardItem?.drugForm}
              </span>
            </p>

            <p className="text-xs text-[#6b7280] mb-1">
              {cardItem?.medicineTypeOfSell}
            </p>
            <p className="text-xs text-[#6b7280] mb-1">
              {cardItem?.composition}
            </p>
          </div>
        </div>


{drugIDsInCart.includes(cardItem.id) && selectedQuantity > 0 ? (
  <div className="flex items-center justify-center w-full mt-2">
    <button
      className="w-[30%] h-10 flex items-center justify-center border border-textGray rounded-l-md"
      onClick={() => handleQuantityChange(selectedQuantity - 1, cardItem)}
      disabled={selectedQuantity <= 1 || isLoading}
    >
      <MinusOutlined style={{ color: selectedQuantity <= 1 ? "#ccc" : undefined }} />
    </button>
    <div className="w-[40%] h-10 flex items-center justify-center border-t border-b border-textGray text-primaryColor font-medium">
      {selectedQuantity}
    </div>
    <button
      className="w-[30%] h-10 flex items-center justify-center border border-textGray rounded-r-md"
      onClick={() => handleQuantityChange(selectedQuantity + 1, cardItem)}
      disabled={isLoading}
    >
      <PlusOutlined />
    </button>
    <DeleteOutlined
      onClick={() => handleDeleteItem(cardItem)}
      style={{ marginLeft: "10px", fontSize: "18px", color: "red", cursor: "pointer" }}
      title="Remove item"
    />
  </div>
) : (
  <div className="flex justify-center">
    <button
      className="w-full border border-[#3b82f6] text-[#2563eb] font-medium text-sm py-[6px] rounded-md hover:bg-[#eff6ff] transition"
      onClick={(e) => handleAddtocart(e, cardItem)}
      disabled={isLoading}
    >
      Add to Cart
    </button>
  </div>
)}


      </div>
    </div>

    {/* We Recommend */}
    <div className="w-full sm:w-1/2 bg-[#f0faf5] rounded-lg p-2 flex flex-col">
      <div className="text-center font-medium text-[#166534] text-base">
        We Recommend
      </div>
      <hr className="border-t-[1.5px] border-[#bbf7d0] my-2" />

      <div className="relative border border-[#bbf7d0] rounded-xl p-3 shadow-md bg-White flex flex-col justify-between h-full">
        {/* Badge */}
        <div className="absolute top-2 left-2 bg-[#065f46] text-White text-[11px] px-2 py-[2px] rounded-md font-medium shadow-sm">
          {(percentagesubstitute).toFixed(2)} % cheaper
        </div>

        <div className='cursor-pointer'  onClick={(e) => redirectToMedicinePage(e, medicineData)}>
          {/* Medicine Image */}
          <div className="flex justify-center mb-3 mt-10">
               <img
                                      src={
                                        medicineData?.medicineImages?.length > 0
                                          ? medicineData?.medicineImages[0]
                                          : defaultMed
                                      }
                                      alt="Alternate Medicine"
                                       className="w-[90px] h-[90px] rounded-lg object-cover bg-White"
                                     
                                    />

          </div>

          {/* Medicine Details */}
          <div className="text-left ">
            <p className="text-primaryColor font-bold text-sm mb-[2px]">
              {medicineData?.medicineName}
            </p>

            <p className="text-sm text-[#1f2937] font-bold mb-[2px]">
              Rs. {medicineData?.medicineRate}
              <span className="text-[#6b7280] font-normal text-xs ml-2">
                Rs.{medicineData?.unitPrice}/{medicineData?.drugForm}
              </span>
            </p>

            <p className="text-xs text-[#6b7280] mb-1">
              {medicineData?.medicineTypeOfSell}
            </p>
            <p className="text-xs text-[#6b7280] mb-1">
              {medicineData?.composition}
            </p>
          </div>
        </div>

        {/* Button */}

        {drugIDsInCart.includes(medicineData?.id) && recommendedQuantity > 0 ? (
  <div className="flex items-center justify-center w-full mt-2">
    <button
      className="w-[30%] h-10 flex items-center justify-center border border-textGray rounded-l-md"
      onClick={() => handleQuantityChange(recommendedQuantity - 1, medicineData)}
      disabled={recommendedQuantity <= 1 || isLoading}
    >
      <MinusOutlined style={{ color: recommendedQuantity <= 1 ? "#ccc" : undefined }} />
    </button>
    <div className="w-[40%] h-10 flex items-center justify-center border-t border-b border-textGray text-primaryColor font-medium">
      {recommendedQuantity}
    </div>
    <button
      className="w-[30%] h-10 flex items-center justify-center border border-textGray rounded-r-md"
      onClick={() => handleQuantityChange(recommendedQuantity + 1, medicineData)}
      disabled={isLoading}
    >
      <PlusOutlined />
    </button>
    <DeleteOutlined
      onClick={() => handleDeleteItem(medicineData)}
      style={{ marginLeft: "10px", fontSize: "18px", color: "red", cursor: "pointer" }}
      title="Remove item"
    />
  </div>
) : (
  <button
    className="w-full border border-[#3b82f6] text-[#2563eb] font-medium text-sm py-[6px] rounded-md hover:bg-[#eff6ff] transition"
    onClick={(e) => handleAddtocart(e, medicineData)}
    disabled={isLoading}
  >
    Add to cart
  </button>
)}

      </div>
    </div>
  </div>
</div>

  );
};

export default SubstituteCard;
